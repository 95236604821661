import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Container } from './container.model';


@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  currentContainer: Container = new Container();
  //retrieveContainersUrl = "http://localhost/php/retrieve_containers.php";
  //retrieveContainerUrl = "http://localhost/php/retrieve_container.php";
  retrieveContainersUrl = "http://grinovaelectronics.com/php/retrieve_containers.php";
  retrieveContainerUrl = "http://grinovaelectronics.com/php/retrieve_container.php";

  httpOptions = {
    headers: new HttpHeaders({
      //'Content-Type':  'application/json',
      'Content-Type':  'application/json',
      responseType: 'text',
      
    })
  };

  constructor(private http: HttpClient) { }

  retrieveContainers(): Observable<string> {
    return this.http.get(this.retrieveContainersUrl, {responseType: 'text'}).pipe(
      catchError(this.handleError)
    );
  }

  retrieveContainer(containerId: string): Observable<string> {
    const params = new HttpParams().set("id_container", containerId);
    return this.http.get(this.retrieveContainerUrl, {responseType: 'text', params: params}).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
