import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Zona } from '../zona.model';
import { ZonaType } from '../zona-type.enum';
import { Patio } from '../patio.model'
import { PatioService } from '../patio.service';
import { ActivatedRoute } from '@angular/router';
import { ContainerService } from '../container.service';

@Component({
  selector: 'app-patio',
  templateUrl: './patio.component.html',
  styleUrls: ['./patio.component.css']
})

export class PatioComponent implements OnInit {

  newPatioForm: FormGroup;
  vistaSuperiorZona = new Zona(0, 0, 500, 450, ZonaType.SUPERIOR);
  vistaLateralZona = new Zona(0, 0, 220, 190, ZonaType.LATERAL);
  vistaFrontalZona = new Zona(0, 0, 220, 190, ZonaType.FRONTAL);
  vistaSuperiorTitle = "Vista Superior";
  vistaLateralTitle = "Vista Lateral";
  vistaFrontalTitle = "Vista Frontal";
  retrievedPatios: Patio[];

  constructor(private formBuilder: FormBuilder,
              private patioService: PatioService,
              private containerService: ContainerService,
              private route: ActivatedRoute) {

    this.patioService.retrievePatios().subscribe(result => {
      this.retrievedPatios = JSON.parse(result);
      console.log(this.retrievedPatios);
      let urlId = Number.parseInt(this.route.snapshot.paramMap.get('id'));
      if (urlId != NaN) {
        this.retrievedPatios.forEach((p: Patio) => {
          if (p.id == urlId) {
            this.patioService.currentPatio = p;
          }
        });
        console.log(this.patioService.currentPatio);

        this.newPatioForm = this.formBuilder.group(this.patioService.currentPatio);
      }     
    });
  }

  ngOnInit() { 
    console.log(this.patioService.currentPatio);

    this.newPatioForm = this.formBuilder.group(this.patioService.currentPatio);

  }

  onSubmit(data) {
    let patio:Patio = Object.assign({}, data);
    console.log(patio);
    this.patioService.storePatio(patio).subscribe(result => {
      console.log(result); 
      this.newPatioForm.reset(new Patio());
    });
    
  }

  changePatio(patioId:number) {
    let patio = this.retrievedPatios.find(x => x.id == patioId);
    this.newPatioForm.reset(patio);
  }

  getCurrentPoiTitle(poi:any) {
    let ba = ("0" + (poi.ba + 1)).substr(-2);
    let z1 = this.newPatioForm.get('ro').value;
    let z2 = this.newPatioForm.get('ti').value;
    let ro2 = poi.ro;
        let isEvenRo = (z1 % 2) == 0;
        let isGreaterRo = (ro2 + 1) > Math.ceil(z1/2);
        let ro3 = Math.abs(ro2  - Math.floor(z1/2)) * 2;
        if (isGreaterRo) {
          if (isEvenRo) {
            ro3 = ro3 + 1;
          } else {
            ro3 = ro3 - 1;
          }
        }
    let ro = ("0" + ro3).substr(-2);
    let ti = ("0" + (z2 - poi.ti + 81)).substr(-2);
    return ba + "/" + ro + "/" + ti;
  }

}
