import { Component, OnInit, Input } from '@angular/core';
import { Zona } from '../zona.model';
import { ZonaType } from '../zona-type.enum';
import { PatioService } from '../patio.service';

@Component({
  selector: 'app-patio-grid',
  templateUrl: './patio-grid.component.html',
  styleUrls: ['./patio-grid.component.css']
})
export class PatioGridComponent implements OnInit {
  @Input() zona: Zona;
  @Input() title: string;
  @Input() rows: number;
  @Input() cols: number;
  @Input() z: number;

  constructor(private patioService: PatioService) { }

  ngOnInit() {
  }

  getCellStyle() {
    let s = {
      'width': Math.floor(this.zona.width() / (this.cols + 1)) + "px",
      'height': Math.floor(this.zona.height() / (this.rows + 1)) + "px"
    }
    return s;
  }

  toArray(i:number) {
    return [...Array(i).keys()];
  }

  isPoI(x:number, y:number):boolean {
    var isPoi = false;
    switch(this.zona.type) {
      case ZonaType.SUPERIOR:
        // x:ro - y:ba
        isPoi = (this.patioService.pointOfInterest.ro === x) && (this.patioService.pointOfInterest.ba === y);
        break;
      case ZonaType.LATERAL:
        // x:ba - y:ti
        isPoi = (this.patioService.pointOfInterest.ba === x) && (this.patioService.pointOfInterest.ti - 81 === y);
        break;
      case ZonaType.FRONTAL:
        // x:ro - y:ti
        isPoi = (this.patioService.pointOfInterest.ro === x) && (this.patioService.pointOfInterest.ti - 81 === y);
        break;
    }
    return isPoi;
  }

  isNearPoI(x:number, y:number):boolean {
    var isNear = false;
    if (this.isPoI(x, y)) {
      return isNear;
    }
    switch(this.zona.type) {
      case ZonaType.SUPERIOR:
        // x:ro - y:ba
        isNear = (this.patioService.pointOfInterest.ro === x) || (this.patioService.pointOfInterest.ba === y);
        break;
      case ZonaType.LATERAL:
        // x:ba - y:ti 
        isNear = (this.patioService.pointOfInterest.ba === x) || (this.patioService.pointOfInterest.ti - 81 === y);
        break;
      case ZonaType.FRONTAL:
        // x:ro - y:ti
        isNear = (this.patioService.pointOfInterest.ro === x) || (this.patioService.pointOfInterest.ti - 81 === y);
        break;
    }
    return isNear;
  }

  getTitle(x:number, y:number): string {
    var title = "";
    let yy = ("0" + (y + 1)).substr(-2);
    let xx = ("0" + (x + 1)).substr(-2);
    let isEven = (this.cols % 2) == 0;
    let isGreater = (x + 1) > Math.ceil(this.cols/2);
    let xx2 = Math.abs(x  - Math.floor(this.cols/2)) * 2;
    if (isGreater) {
      if (isEven) {
        xx2 = xx2 + 1;
      } else {
        xx2 = xx2 - 1;
      }
    }
    let xx3 = ("0" + (xx2)).substr(-2);
    switch(this.zona.type) {
      case ZonaType.SUPERIOR:
        // x:ro - y:ba
        title = yy + "/" + xx3 + "/" + (this.z - this.patioService.pointOfInterest.ti + 81);
        break;
      case ZonaType.LATERAL:
        // x:ba - y:ti 
        let ro = this.patioService.pointOfInterest.ro;
        let isEvenRo = (this.z % 2) == 0;
        let isGreaterRo = (ro + 1) > Math.ceil(this.z/2);
        let ro2 = Math.abs(ro  - Math.floor(this.z/2)) * 2;
        if (isGreaterRo) {
          if (isEvenRo) {
            ro2 = ro2 + 1;
          } else {
            ro2 = ro2 - 1;
          }
        }
        let ro3 = ("0" + (ro2)).substr(-2);
        title = xx + "/" + ro3 + "/" + ((this.rows - y) + 81);
        break;
      case ZonaType.FRONTAL:
        // x:ro - y:ti
        title = ("0" + (this.patioService.pointOfInterest.ba + 1)).substr(-2) + "/" + xx3 + "/" + ((this.rows - y) + 81);
        break;
    }
    return title;
  }

  updatePoI(x:number, y:number): void {
    switch(this.zona.type) {
      case ZonaType.SUPERIOR:
        // x:ba - y:ro
        this.patioService.pointOfInterest.ro = x;
        this.patioService.pointOfInterest.ba = y;
        break;
      case ZonaType.LATERAL:
        // x:ba - y:ti 
        this.patioService.pointOfInterest.ba = x;
        this.patioService.pointOfInterest.ti = y + 81;
        break;
      case ZonaType.FRONTAL:
        // x:ti - y:ro
        this.patioService.pointOfInterest.ro = x;
        this.patioService.pointOfInterest.ti = y + 81;
        break;
    }
  }

}
