import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpParams } from '@angular/common/http';
import { Patio } from './patio.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class PatioService {

  currentPatio: Patio;
  pointOfInterest: any;
  
//  savePatioUrl = "http://localhost/php/save_patios.php";
//  retrievePatioUrl = "http://localhost/php/retrieve_patios.php";
//  geoSearchPatioUrl = "http://localhost/php/geo_search_patio.php";
  savePatioUrl = "http://grinovaelectronics.com/php/save_patios.php";
  retrievePatioUrl = "http://grinovaelectronics.com/php/retrieve_patios.php";
  geoSearchPatioUrl = "http://grinovaelectronics.com/php/geo_search_patio.php";

  
  httpOptions = {
    headers: new HttpHeaders({
      //'Content-Type':  'application/json',
      'Content-Type':  'application/json',
      responseType: 'text',
      
    })
  };
  

  constructor(private http: HttpClient) { 
    this.currentPatio = new Patio();
    this.pointOfInterest = {
      ba: 1,
      ro: 1,
      ti: 82
    }
  }

  storePatio (patio: Patio): Observable<string> {
    /*var req = new HttpRequest('POST', this.savePatioUrl, patio, {
      reportProgress: true,
      responseType: 'text'
    });
    console.log(req);
    return this.http.request(req);*/
    return this.http.post<string>(this.savePatioUrl, patio, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );

    
  }

  retrievePatios(): Observable<string> {
    return this.http.get(this.retrievePatioUrl, {responseType: 'text'}).pipe(
      catchError(this.handleError)
    );
  }

  geoSearchPatio(lat: number, lon: number): Observable<string> {
    const params = new HttpParams().set("lat", ""+lat).set("lon", ""+lon);
    return this.http.get(this.geoSearchPatioUrl, {responseType: 'text', params: params}).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
