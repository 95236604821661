import { ZonaType } from './zona-type.enum';

export class Zona {
    med_x: number;
    med_y: number;

    constructor(public min_x: number,
                public min_y: number,
                public max_x: number,
                public max_y: number,
                public type: ZonaType) {
        this.med_x = (max_x - min_x) / 2;
        this.med_y = (max_y - min_y) / 2;
    }

    width(): number {
        return this.max_x - this.min_x;
    }

    height(): number {
        return this.max_y - this.min_y;
    }
}
