import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Zona } from '../zona.model';
import { PatioService} from '../patio.service';
import { ZonaType } from '../zona-type.enum';

@Component({
  selector: 'app-patio-canvas',
  templateUrl: './patio-canvas.component.html',
  styleUrls: ['./patio-canvas.component.css']
})
export class PatioCanvasComponent implements OnInit {

  @Input() zona: Zona;
  @Input() title: string;

  @ViewChild('canvas_vista', {static: false}) myCanvas: ElementRef;
  public ctx: CanvasRenderingContext2D;

  constructor(private patioService: PatioService) { }

  ngOnInit() { }

  ngAfterViewInit() { 
    this.ctx = this.myCanvas.nativeElement.getContext('2d');
    this.draw();
  }

  draw() {
    this.ctx.clearRect(this.zona.min_x, this.zona.min_y, this.zona.max_x, this.zona.max_y);
    this.ctx.beginPath();
    this.ctx.stroke();

    var col_num = 5;
    var row_num = 3;
    [row_num, col_num] = this.getRowColNum();

    var col_paso = (this.zona.max_x - this.zona.min_x)/col_num;

    for (var cols = 0; cols < col_num; cols++)
    {  
        this.ctx.moveTo(this.zona.min_x + col_paso*cols, this.zona.min_y);
        this.ctx.lineTo(this.zona.min_x + col_paso*cols, this.zona.max_y);
        this.ctx.stroke();
    } 

    var row_paso = (this.zona.max_y - this.zona.min_y)/row_num;
    for (var fils = 0; fils < row_num; fils++)
    {  
        this.ctx.moveTo(this.zona.min_x, this.zona.min_y+row_paso*fils);
        this.ctx.lineTo(this.zona.max_x, this.zona.min_y+row_paso*fils);
        this.ctx.stroke();
    } 
  }

  getRowColNum(): [number, number] {
    var rowcol: [number, number] = [1, 1];
    switch(this.zona.type) {
      case ZonaType.SUPERIOR:
        rowcol = [this.patioService.currentPatio.ba, this.patioService.currentPatio.ro];
        break;
      case ZonaType.LATERAL:
        rowcol = [this.patioService.currentPatio.ti - 81, this.patioService.currentPatio.ba];
        break;
      case ZonaType.FRONTAL:
        rowcol = [this.patioService.currentPatio.ti - 81, this.patioService.currentPatio.ro];
        break;
    }
    return rowcol;
  }
}
