import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatioComponent } from './patio/patio.component';
import { ContainerComponent } from './container/container.component';


const routes: Routes = [
  {path: 'patio/:id', component: PatioComponent},
  {path: 'patio', component: PatioComponent, data: { newPatio: true}},
  {path: 'container/:id', component: ContainerComponent},
  {path: 'container', component: ContainerComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
