export class Patio {
    id: number = 0;
    ba: number = 1;
    ro: number = 1;
    ti: number = 82;
    nombre: string = '';
    comentarios: string = '';
    prba_lat: number = 0.0;
    prba_lon: number = 0.0;
    poba_lat: number = 0.0;
    poba_lon: number = 0.0;
    pres_lat: number = 0.0;
    pres_lon: number = 0.0;
    poes_lat: number = 0.0;
    poes_lon: number = 0.0;
    altitud: number = 0.0;
}
