import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PatioComponent } from './patio/patio.component';
import { MainComponent } from './main/main.component';
import { PatioCanvasComponent } from './patio-canvas/patio-canvas.component';
import { PatioGridComponent } from './patio-grid/patio-grid.component';
import { ContainerComponent } from './container/container.component';

@NgModule({
  declarations: [
    AppComponent,
    PatioComponent,
    MainComponent,
    PatioCanvasComponent,
    PatioGridComponent,
    ContainerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [PatioComponent]
})
export class AppModule { }
