import { Component, OnInit } from '@angular/core';
import { PatioService } from '../patio.service';
import { Zona } from '../zona.model';
import { ZonaType } from '../zona-type.enum';
import { ContainerService } from '../container.service';
import { Container } from '../container.model';
import { Patio } from '../patio.model';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class ContainerComponent implements OnInit {
  vistaSuperiorZona = new Zona(0, 0, 500, 450, ZonaType.SUPERIOR);
  vistaLateralZona = new Zona(0, 0, 220, 190, ZonaType.LATERAL);
  vistaFrontalZona = new Zona(0, 0, 220, 190, ZonaType.FRONTAL);
  retrievedContainers: Container[];
  patioName: string = "";

  constructor(private patioService: PatioService,
              private containerService: ContainerService) {
    this.containerService.retrieveContainers().subscribe(result => {
      console.log(result);
      this.retrievedContainers = JSON.parse(result);
      console.log(this.retrievedContainers);
    });
    if (this.containerService.currentContainer.id != -1) {
      this.geoSearchPatio(this.containerService.currentContainer);
    }
  }

  ngOnInit() {
  }

  changeContainer(containerId:string) {
    let container = this.retrievedContainers.find(x => x.id_container === containerId);
    this.containerService.retrieveContainer(container.id_container).subscribe(result => {
      console.log(result);
      this.containerService.currentContainer = JSON.parse(result)[0];
      this.geoSearchPatio(this.containerService.currentContainer);
    });
  }

  geoSearchPatio(container: Container) {
    this.patioService.geoSearchPatio(container.lat, container.lon).subscribe(result => {
      console.log(result);
      this.patioService.currentPatio = JSON.parse(result)[0];
      console.log(this.patioService.currentPatio);
      this.patioName = this.patioService.currentPatio.nombre;
      this.computePoi();
    });
  }

  computePoi() {
    this.patioService.pointOfInterest = {
      ba: 1,
      ro: 1,
      ti: 82,
    }

    let cont_lat = this.containerService.currentContainer.lat;
    let cont_lon = this.containerService.currentContainer.lon;
    let cont_alt = this.containerService.currentContainer.alt;

    let prba_lat = this.patioService.currentPatio.prba_lat;
    let pres_lat = this.patioService.currentPatio.pres_lat;
    let poba_lat = this.patioService.currentPatio.poba_lat;
    let poes_lat = this.patioService.currentPatio.poes_lat;

    let prba_lon = this.patioService.currentPatio.prba_lon;
    let pres_lon = this.patioService.currentPatio.pres_lon;
    let poba_lon = this.patioService.currentPatio.poba_lon;
    let poes_lon = this.patioService.currentPatio.poes_lon;

    let alt = this.patioService.currentPatio.altitud;


    // asumiendo líneas paralelas.
    // m*lat + n = lon
    let ba_m = (prba_lon - poba_lon) /
               (prba_lat - poba_lat);
    let ba_n_1 = prba_lon - prba_lat * ba_m;
    let ba_n_2 = pres_lon - pres_lat * ba_m;
    
    let ba_step = (ba_n_2 - ba_n_1)/this.patioService.currentPatio.ba;
    let ba_offset = ba_n_1 + (ba_step/2);

    var min_dist_ba = 1;
    var min_dist = 999999;
    for (var i = 0; i < (this.patioService.currentPatio.ba); i++) {
      let dist = Math.abs(ba_m*cont_lat - cont_lon + ba_offset + ba_step*i)/Math.sqrt(ba_m*ba_m + 1);
      if (dist < min_dist) {
        min_dist = dist;
        min_dist_ba = i;
      }
    }

    // m*lat + n = lon
    let ro_m = (prba_lon - pres_lon) /
               (prba_lat - pres_lat);
    let ro_n_1 = prba_lon - prba_lat * ro_m;
    let ro_n_2 = poes_lon - poes_lat * ro_m;

    let ro_step = (ro_n_2 - ro_n_1)/this.patioService.currentPatio.ro;
    let ro_offset = ro_n_1 + (ro_step/2);

    var min_dist_ro = 1;
    min_dist = 999999;
    for (var i = 0; i < (this.patioService.currentPatio.ro); i++) {
      let dist = Math.abs(ro_m*cont_lat - cont_lon + ro_offset + ro_step*i)/Math.sqrt(ro_m*ro_m + 1);

      if (dist < min_dist) {
        min_dist = dist;
        min_dist_ro = i;
      }
    }

    let ti = this.patioService.currentPatio.ti - Math.round((cont_alt - alt) / 3); 

    this.patioService.pointOfInterest = {
      ba: min_dist_ba,
      ro: min_dist_ro,
      ti: ti
    }
    console.log(this.patioService.pointOfInterest);
  }

  getCurrentPoiTitle(poi:any) {
    let ba = ("0" + (poi.ba + 1)).substr(-2);
    let z1 = this.patioService.currentPatio.ro;
    let z2 = this.patioService.currentPatio.ti;
    let ro2 = poi.ro;
        let isEvenRo = (z1 % 2) == 0;
        let isGreaterRo = (ro2 + 1) > Math.ceil(z1/2);
        let ro3 = Math.abs(ro2  - Math.floor(z1/2)) * 2;
        if (isGreaterRo) {
          if (isEvenRo) {
            ro3 = ro3 + 1;
          } else {
            ro3 = ro3 - 1;
          }
        }
    let ro = ("0" + ro3).substr(-2);
    let ti = ("0" + (z2 - poi.ti + 81)).substr(-2);
    return ba + "/" + ro + "/" + ti;
  }
}
